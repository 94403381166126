<template>
  <div :class="`button__wrapper button__input ${
    setSize()} ${setType()} ${setDisable()} ${setBlock()}`">
    <input
      style="opacity: 0;"
      id="input__file"
      type="file"
      :disabled="disabled === true"
      @change="handleChange"
      :multiple="multiple"
       />
    <label for="input__file" class="button__input_label">
      <span>{{text}}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'Button',
  data() {
    return {
    };
  },
  props: {
    text: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'medium',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setSize() {
      return `button__wrapper_${this.size}`;
    },
    setType() {
      return `button__wrapper_${this.type}`;
    },
    setDisable() {
      return this.disabled ? 'button__wrapper_disabled' : '';
    },
    setBlock() {
      return this.block ? 'button__wrapper_block' : '';
    },
    handleChange(evt) {
      if (!this.disabled) {
        this.$emit('change', evt.target.files);
      }
    },
  },
};
</script>
