<template>
  <div>
    <div class="tech-req__title">
      {{tech.menu_material[language]}}
    </div>
    <div class="tech-req__card">
      <div class="tech-req__card_container">
        <Search
          required
          v-if="edit"
          :label="tech.m_material[language]"
          :default-value="partParams && partParams.material ? partParams.material : ''"
          clearable
          @clear="save('id_material', null)"
          @change="save('id_material', $event.value)"
          :query="{
            method: 'post',
            url: 'getMaterialsAsArray',
            data: {
              lang: this.language,
            },
            label: 'name',
            value: 'id',
          }"
        />
        <div v-if="!edit" style="margin-bottom: 15px;">
          <div
            class="tech-req__gears_param">
            {{tech.m_material[language]}}
          </div>
          <div
            class="tech-req__gears_letter">
            {{partParams && partParams.material ? partParams.material : '-'}}
          </div>
        </div>
        <Search
          v-if="edit && partParams.id_material"
          :label="tech.m_gost[language]"
          :default-value="partParams && partParams.gost_material ? partParams.gost_material : ''"
          clearable
          @clear="save('id_gost_material', null)"
          @change="save('id_gost_material', $event.value)"
          :query="{
                method: 'post',
                url: 'getSearchStandards',
                label: 'standard_code',
                value: 'id',
              }"
          name="gost_material" />
        <div v-if="!edit" style="margin-bottom: 15px;">
          <div
            class="tech-req__gears_param">
            {{tech.m_gost[language]}}
          </div>
          <div
            class="tech-req__gears_letter">
            {{partParams && partParams.gost_material ? partParams.gost_material : '-'}}
          </div>
        </div>
      </div>
    </div>
    <div class="tech-req__card" v-if="partParams && partParams.id_material">
      <div class="tech-req__card_container">
        <Search
          v-if="edit"
          :label="tech.m_blank[language]"
          :query="{
                method: 'post',
                url: 'getSearchBlanks',
                label: 'blank_type',
                value: 'id',
              }"
          @clear="setBlankType(null)"
          :default-value="partParams && partParams.blank_type ? partParams.blank_type : ''"
          clearable
          @change="setBlankType($event.value)"
          name="blank_type" />
        <div v-if="!edit" style="margin-bottom: 15px;">
          <div
            class="tech-req__gears_param">
            {{tech.m_blank[language]}}
          </div>
          <div
            class="tech-req__gears_letter">
            {{partParams && partParams.blank_type ? partParams.blank_type : '-'}}
          </div>
        </div>
        <Search
          v-if="edit"
          :label="tech.m_gost_blank[language]"
          :default-value="partParams && partParams.gost_blank ? partParams.gost_blank : ''"
          clearable
          @clear="save('id_gost_blank', null)"
          @change="save('id_gost_blank', $event.value)"
          :query="{
                method: 'post',
                url: 'getSearchStandards',
                label: 'standard_code',
                value: 'id',
              }"
          name="gost_blank" />
        <div v-if="!edit" style="margin-bottom: 15px;">
          <div
            class="tech-req__gears_param">
            {{tech.m_gost_blank[language]}}
          </div>
          <div
            class="tech-req__gears_letter">
            {{partParams && partParams.gost_blank ? partParams.gost_blank : '-'}}
          </div>
        </div>
        <div v-if="partParams && blankParams && blankParams.sizes
        && blankParams.sizes.filter((el) => el !== '3d').length > 0">
          <div :style="`margin-bottom: 15px; display: block; font-family: 'Noto Sans Mono',`
          + ' sans-serif; font-size: 16px; font-weight: 700; line-height: 35px;'">
            {{tech.m_blank_size[language]}}
          </div>
          <div>
            <div v-if="blankParams && blankParams.img">
              <img :src="blankParams.img" style="max-width: 100%" />
            </div>
            <div v-if="blankParams && blankParams.sizes" style="width: 100%;"
                 class="tech-req__material-and-blanks_sizes-container">
              <div
                style="width: 100%"
                class="tech-req__material-and-blanks_sizes-input"
                v-for="(size, i) in blankParams.sizes.filter((el) => el !== '3d')"
                v-bind:key="`sizes_${i}`" >
                <div
                  style="margin-bottom: 10px"
                  v-if="edit"
                  class="block__flex block__align-center">
                  <div class="tech-req__gears_letter"
                       style="margin-right: 10px; min-width: 18px;">{{size}}</div>
                  <Input
                    @change="resetTimer(size, $event)"
                    :default-value="partParams && partParams.blank_size
                    && partParams.blank_size[size] ? partParams.blank_size[size] : ''"
                    type="number"
                    :max-width="80"
                    :margin-bottom="0" />
                  <div class="tech-req__gears_letter" style="margin-left: 10px;">
                    {{tech.mm[language]}}
                  </div>
                </div>
                <div v-if="!edit && partParams && partParams.blank_size
                    && partParams.blank_size[size]"
                     style="margin-bottom: 10px;">
                  <span>
                    <span class="tech-req__gears_letter" style="min-width: 18px;">
                      {{size}}
                    </span>
                    <span class="tech-req__gears_param">
                      {{partParams && partParams.blank_size
                    && partParams.blank_size[size] ? partParams.blank_size[size] : ''}}
                    </span>
                    <span class="tech-req__gears_letter">
                       {{tech.mm[language]}}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="edit && blankParams && blankParams.sizes
        && blankParams.sizes.filter((el) => el === '3d').length > 0">
          <InputButton
            block
            :disabled="true"
            type="primary"
            :text="tech.m_load_3d[language]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tech from '@/assets/text/techV2.json';
import { query } from '@/utilites/axios';
import Search from '@/components/search/Search.vue';
import Input from '@/components/inputV2/Input.vue';
import InputButton from '@/components/inputButton/InputButton.vue';

export default {
  name: 'MaterialAndBlank',
  data() {
    return {
      tech,
      defaultMaterial: null,
      blankValue: 1,
      showMaterialGost: false,
      showMethodType: false,
      blankType: null,
      blankParams: null,
      partParams: null,
      timer: null,
      saveData: {},
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  props: {
    partData: {
      type: Object,
    },
    edit: {
      type: Boolean,
    },
  },
  watch: {
    language: function () {
      this.getData();
    },
  },
  methods: {
    materialChange() {
      this.showMaterialGost = true;
    },
    changeBlank(e) {
      this.blankValue = e.target.value;
    },
    setMethod(e) {
      this.$store.commit('tech/setMethodId', e.id_method);
      this.showMethodType = true;
    },
    async getPartData() {
      await this.$store.dispatch('part/getDataForUploadedFile', {
        data: {
          hash: this.$route.query.h,
          time_stamp: this.$route.query.t,
          file_name: this.$route.query.n,
          lang: this.language,
        },
      }).then((item) => {
        if (item && item.part) {
          this.defaultMaterial = item.part.material_name;
        }
      });
    },
    findMaterials(val) {
      return new Promise((resolve) => {
        query('post',
          'getMaterialsAsArray', {
            find: val,
            lang: this.language,
          }, {},
          () => {}).then((items) => {
          resolve(items);
        });
      });
    },
    getBlankParams(id) {
      query('post', 'getBlankParams', {
        id_blank_type: id,
      }, {
        token: localStorage.getItem('token'),
      }).then((res) => {
        this.blankParams = res[0];
      });
    },
    setBlankType(val) {
      this.blankType = val;
      if (val) {
        this.getBlankParams(this.blankType);
      } else {
        this.blankParams = null;
      }
      this.save('id_blank_type', val);
    },
    startTimer() {
      this.timer = setTimeout(() => {
        this.saveBlankParam(this.saveData);
      }, 600);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    resetTimer(name, val) {
      this.saveData[name] = +val;
      this.stopTimer();
      this.startTimer();
    },
    saveBlankParam(savedData) {
      let data = this.partParams.blank_size ? this.partParams.blank_size : {};
      data = { ...data, ...savedData };
      this.save('blank_size', data);
    },
    save(name, value) {
      if (this.partParams) {
        const data = {
          hash: this.partData.hash,
          time_stamp: this.partData.time_stamp,
          file_name: this.partData.file_name,
          uuid: this.$route.params.uuid,
          material_and_blank: {
            id_material: this.partParams.id_material,
            id_gost_material: this.partParams.id_gost_material
              ? this.partParams.id_gost_material : null,
            id_blank_type: this.partParams.id_blank_type
              ? this.partParams.id_blank_type : null,
            id_gost_blank: this.partParams.id_gost_blank
              ? this.partParams.id_gost_blank : null,
            blank_size: this.partParams.blank_size
              ? this.partParams.blank_size : null,
          },
        };
        data.material_and_blank[name] = value;
        query('post', 'changeTechDataByPartOrder', data, {
          token: localStorage.getItem('token'),
        }).then(() => {
          this.getData();
        });
      }
    },
    getMaterialAndBlank() {
      return new Promise((resolve) => {
        query('post', 'getTechDataByPartOrder', {
          material_and_blank: true,
          uuid: this.$route.params.uuid,
        }, {
          token: localStorage.getItem('token'),
        }).then((result) => {
          this.partParams = result.material_and_blank;
          resolve(result.material_and_blank);
        });
      });
    },
    getData() {
      this.getMaterialAndBlank().then((res) => {
        if (res && res.id_blank_type) {
          this.getBlankParams(res.id_blank_type);
        }
      });
    },
  },
  mounted() {
    this.getData();
  },
  components: {
    Search,
    Input,
    InputButton,
  },
};
</script>
